<script setup>
 const { $bus } = useNuxtApp();
 const props = defineProps({
  opened: {
   type: Boolean,
   required: true,
  },
  classes: {
   type: String,
   required: false,
   default: '',
  },
 });

 // Props
 const { opened } = toRefs(props);

 // Emits
 const emit = defineEmits(['close']);

 // Events
 const handleClose = () => {
  window.removeEventListener('keydown', (event) => {
   if (event.key === 'Escape') handleClose();
  });
  emit('close');
 };

 // Watchers
 watch(opened, (value) => {
  if (value) $bus.emit('scroll-lock');
  else $bus.emit('scroll-unlock');
 });

 onMounted(() => {
  window.addEventListener('keydown', (event) => {
   if (event.key === 'Escape') handleClose();
  });
 });
</script>

<template>
 <Teleport to="body" v-if="opened">
  <MessFragmentsFocusLock :showing="opened">
   <div class="mess-modal" :class="classes" @click.self="handleClose">
    <div class="body">
     <button @click="handleClose" class="close">
      <slot name="close" />
     </button>
     <slot name="content" />
    </div>
   </div>
  </MessFragmentsFocusLock>
 </Teleport>
</template>

<style lang="scss">
 .mess-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483641;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(5px);

  cursor: pointer;
  overflow-y: auto;

  .body {
   cursor: auto;
   position: relative;
   max-height: calc(95 * var(--vh));

   .close {
    position: absolute;
    top: -50px;
    right: 0;
    background: transparent;
    border: none;
    cursor: pointer;
   }
  }
 }
</style>
